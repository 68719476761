<template>
  <div>
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate color="#137547"></v-progress-circular>
    </v-overlay>
    <p>Kategorija: {{ this.currentProduct.categoryName }}</p>
    <p>Podkategorija: {{ this.currentProduct.subcategoryName }}</p>
    <div class="productInfoWrapper">
      <div class="productInfoLeft">
        <v-form ref="form" lazy-validation>
          <v-text-field
            v-model.trim="product.name"
            color="#137547"
            background-color="#ececef"
            outlined
            label="Naziv oglasa"
            placeholder="Unesite naziv oglasa"
            type="text"
            class="nameField"
            :rules="nameRules"
          ></v-text-field>
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="product.price"
                outlined
                color="#137547"
                background-color="#ececef"
                label="Cena"
                placeholder="Unesite cenu"
                type="number"
                min="0"
                suffix="(RSD)"
                :error-messages="priceError"
                @input="changePriceFunction"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="product.otherPaymentOption"
                outlined
                label="Ili umesto cene"
                item-text="name"
                item-value="id"
                :items="otherPaymentOptions"
                color="#137547"
                background-color="#ececef"
                @change="changePaymentMethodFunction"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-textarea
            v-model="product.description"
            label="Tekst oglasa"
            auto-grow
            outlined
            counter="1000"
            :rules="descriptionRules"
            color="#137547"
            background-color="#ececef"
          ></v-textarea>
          <v-col>
            <v-checkbox v-model="product.isPriceFixed" label="Cena je fiksna" color="#137547" :disabled="!(this.product.price > 0)"></v-checkbox>
            <v-checkbox v-model="product.isExchangePossible" label="Prihvatam zamenu" color="#137547"></v-checkbox>
          </v-col>
        </v-form>
      </div>
      <div class="productInfoRight">
        <v-text-field
          :value="`${this.currentLoggedUser.firstName} ${this.currentLoggedUser.lastName}`"
          label="Ime i prezime"
          outlined
          readonly
          color="#137547"
          background-color="#ececef"
        ></v-text-field>
        <v-row>
          <v-col> <v-checkbox v-model="product.useMyLocation" @change="useMyLocationChanger" label="Koristi moju lokaciju" color="#137547"></v-checkbox> </v-col> </v-row
        ><v-row
          ><v-col>
            <v-text-field
              :value="this.currentLoggedUser.location"
              :disabled="!product.useMyLocation"
              label="Lokacija"
              outlined
              readonly
              color="#137547"
              background-color="#ececef"
              class="productField"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="product.location"
              :disabled="product.useMyLocation"
              label="Koristi drugu lokaciju"
              outlined
              color="#137547"
              background-color="#ececef"
              class="productField"
              :rules="locationRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col> <v-checkbox v-model="product.useMyPhoneNum" @change="useMyPhoneNumChanger" label="Koristi moj broj telefona" color="#137547"></v-checkbox> </v-col></v-row
        ><v-row
          ><v-col>
            <v-text-field
              :value="this.currentLoggedUser.phoneNumber"
              :disabled="!product.useMyPhoneNum"
              label="Broj telefona"
              outlined
              readonly
              color="#137547"
              background-color="#ececef"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="product.phoneNumber"
              :disabled="product.useMyPhoneNum"
              label="Koristi drugi broj telefona"
              outlined
              color="#137547"
              background-color="#ececef"
              :rules="phoneNumberRules"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-card-actions class="nextStepButton">
      <v-btn color="#fff" @click="goBack">
        <p style="margin: auto; color: #137547">NAZAD</p>
      </v-btn>
      <v-btn color="#137547" @click="submitForm">
        <p style="margin: auto; color: #ffff">SLEDEĆI KORAK</p>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ProductInfo",
  data() {
    return {
      loading: false,
      otherPaymentOptions: [
        { id: 1, name: "Dogovor" },
        { id: 2, name: "Kontakt" },
        { id: 3, name: "Pozvati" },
        { id: 4, name: "Besplatno" },
      ],
      product: {
        name: "",
        price: "",
        otherPaymentOption: 0,
        isPriceFixed: false,
        isExchangePossible: false,
        description: "",
        useMyLocation: true,
        location: "",
        useMyPhoneNum: true,
        phoneNumber: "",
      },
      nameRules: [(v) => !!v || "Ime je obavezno.", (v) => (v && v.length <= 50) || "Ime ne može da ima više od 50 karaktera."],
      descriptionRules: [(v) => v.length <= 1000 || "Opis ne može da ima više od 1000 karaktera."],
      priceError: "",
      locationRules: [
        (v) => !!v || "Polje ne može ostati prazno.",
        (v) => /^[a-zA-Z]+$/.test(v.replace(/ /g, "")) || "Dozvoljena su samo slova u nazivu mesta.",
        (v) => v === v.trim() || "Izbrišite nepotrebne razmake.",
      ],
      phoneNumberRules: [(v) => !!v || "Polje ne može ostati prazno.", (v) => /^[+\d](?:.*\d)?$/.test(v) || "Dozvoljen je unos samo brojeva."],
    };
  },
  methods: {
    checkPriceValidity() {
      if (!(this.product.price > 0) && !(this.product.otherPaymentOption > 0)) {
        this.priceError = "Cena mora biti navedena.";
        return false;
      } else {
        this.priceError = "";
        return true;
      }
    },
    ...mapActions(["AddProductInfo"]),
    async submitForm() {
      if (this.$refs.form.validate() && this.checkPriceValidity()) {
        await this.AddProductInfo(this.product);
        this.$emit("goToNext");
      }
    },
    goBack() {
      this.$emit("goToPrevious");
    },
    changePriceFunction() {
      this.product.price = this.product.price.replace(/^0+/, "");
      if (this.product.price > 0) {
        this.product.otherPaymentOption = 0;
        this.checkPriceValidity();
      }
    },
    changePaymentMethodFunction() {
      if (this.product.otherPaymentOption > 0) {
        this.product.price = "";
        this.product.isPriceFixed = false;
        this.checkPriceValidity();
      }
    },
    useMyLocationChanger() {
      this.product.location = "";
    },
    useMyPhoneNumChanger() {
      this.product.phoneNumber = "";
    },
    resetValues() {
      let initialValues = {
        name: "",
        price: "",
        otherPaymentOption: 0,
        isPriceFixed: false,
        isExchangePossible: false,
        description: "",
        useMyLocation: true,
        location: "",
        useMyPhoneNum: true,
        phoneNumber: "",
      };
      this.product = initialValues;
    },
  },
  props: {
    user: {},
  },
  computed: {
    currentLoggedUser() {
      return this.$store.getters.StateCurrentUser;
    },
    currentProduct() {
      return this.$store.getters.StateNewProduct;
    },
    productOwner: {
      get() {
        return this.user;
      },
      set() {
        //
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.product.name = this.currentProduct.name;
      this.product.price = this.currentProduct.price;
      this.product.otherPaymentOption = this.currentProduct.otherPaymentOption;
      this.product.isPriceFixed = this.currentProduct.isPriceFixed;
      this.product.isExchangePossible = this.currentProduct.isExchangePossible;
      this.product.description = this.currentProduct.description;
      this.product.useMyLocation = this.currentProduct.useMyLocation;
      this.product.location = this.currentProduct.location;
      this.product.useMyPhoneNum = this.currentProduct.useMyPhoneNum;
      this.product.phoneNumber = this.currentProduct.phoneNumber;
    });
  },
};
</script>

<style lang="scss" scoped>
.productField {
  display: flex;
}
.dropBtn {
  margin: 5px auto;
  display: flex;
}
.v-card__text {
  padding: 1rem;
}

.nextStepButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nameField {
  /* border-bottom: 1px solid #ccc; */
}

@media screen and (min-width: 768px) {
  .productInfoWrapper {
    display: flex;
    justify-content: space-between;
    .productInfoLeft,
    .productInfoRight {
      margin: 0.5rem;
    }
  }
}
</style>
